import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { NavController } from '@ionic/angular';
import { LoginData } from '@pages/login/login/login.interfaces';
import {
    FaultSuggestionType,
    FleetManagerProfileDataDto,
    LoginUserType,
    MakeDataDto,
    ServiceEmployeeDataDto,
    ServiceUserType
} from '@services/backend/models/generated-models';
import { LanguageService } from '@services/language/language.service';
import { PlatformService } from '@services/platform/platform.service';
import { CustomHeaders } from '@shared/custom-headers';
import { PageReuseStrategy } from '@shared/page-reuse-strategy';
import { IList } from 'linq-collections';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { BackendService } from '../backend/backend.service';
import { HttpCache } from '../backend/http-cache';
import { ListSettingService } from '../list-settiing/list-setting.service';
import { NotificationService } from '../notification/notification.service';
import { PermissionService } from '../permission/permission.service';
import { StorageService } from '../storage/storage.service';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({ providedIn: 'root' })
export class UserService {

    // region Fields

    isLoggedIn: boolean;

    serviceId: string;
    deliveryEnabled: boolean;
    suzukiDealerCode: string;

    userId: string;
    userEMail: string;
    userName: string;
    userType: ServiceUserType;
    picture: string;
    serviceLogo: string;

    loginUserType: LoginUserType;

    makes: IList<MakeDataDto>;

    faultSuggestions: Array<string>;
    repairPartSuggestions: Array<string>;

    afterLoginEvent: Subject<void> = new Subject<void>();
    onLogoutEvent: Subject<void> = new Subject<void>();

    // region Auth token

    getAuthToken(): string {
        return this.storage.getAuthToken();
    }

    setAuthToken(value: string): void {
        this.storage.setAuthToken(value);
    }

    clearAuthToken(): void {
        this.storage.clearAuthToken();
    }

    getRefreshToken(): string {
        return this.storage.getRefreshToken();
    }

    setRefreshToken(value: string): void {
        this.storage.setRefreshToken(value);
    }

    clearRefreshToken(): void {
        this.storage.clearRefreshToken();
    }

    // endregion

    // region Last user name

    getLastUserAccountName(): string | null {
        return this.storage.getLastUserName();
    }

    setLastUserAccountName(value: string): void {
        this.storage.setLastUserName(value);
    }

    // endregion

    // endregion

    // region Initialization

    constructor(
        private navCtrl: NavController,
        private platform: PlatformService,
        private storage: StorageService,
        private permissions: PermissionService,
        private listSetting: ListSettingService,
        private language: LanguageService,
        private backend: BackendService,
        private notification: NotificationService,
        private httpCache: HttpCache,
        private routeReuse: RouteReuseStrategy
    ) {
        this.init();
    }

    init() {
        this.isLoggedIn = false;
        this.serviceId = null;
        this.userId = null;
        this.userEMail = null;
        this.userName = null;
        this.picture = null;
        this.suzukiDealerCode = null;

        this.faultSuggestions = [];
        this.repairPartSuggestions = [];
    }

    // endregion

    // region Login/Logout

    login(loginData: LoginData): Promise<boolean> {
        let result: boolean = false;
        return new Promise(resolve => {
            this.backend.Login({ eMail: loginData.userEMail, password: loginData.password, platform: this.platform.getDevicePlatform() })
                .pipe(
                    switchMap(() => {
                        result = true;
                        return this.afterLogin();
                    }),
                    finalize(() => resolve(result)),
                    catchError((err: HttpErrorResponse) => {
                        if (err.error.ShowMessage) {
                            this.notification.showCustomError(err.error.Error);
                        }
                        else {
                            this.notification.showGenericError();
                        }
                        return of(err);
                    })
                )
                .subscribe();
        });
    }

    afterLogin(): Promise<any> {
        this.isLoggedIn = true;

        this.afterLoginEvent.next();

        let settingObservable = this.backend.GetListSetting(this.userId);
        let userData = this.loginUserType == LoginUserType.FleetManager ? this.backend.GetFleetManagerProfile() : this.backend.GetUser({ id: this.userId });
        let makes = this.backend.GetMakes();

        return forkJoin([settingObservable, userData, makes])
            .toPromise()
            .then(result => {
                Sentry.setUser({ id: this.userId });

                this.listSetting.initSettings(result[0]);

                let user: ServiceEmployeeDataDto | FleetManagerProfileDataDto = result[1];
                this.serviceId = this.loginUserType == LoginUserType.FleetManager ? null : (user as any).serviceId;
                this.userName = user.name;
                this.userEMail = user.eMail;
                this.userType = user.type;
                this.picture = user.picture;
                this.serviceLogo = this.loginUserType == LoginUserType.FleetManager ? (user as any).logo : (user as any).serviceLogo;

                this.setLastUserAccountName(this.userEMail);

                this.makes = result[2];

                if (this.userType != ServiceUserType.FleetManager) {
                    this.backend.GetService({ id: this.serviceId })
                        .subscribe(service => {
                            this.deliveryEnabled = service.deliveryEnabled;
                            this.suzukiDealerCode = service.dealerCode;
                            this.language.initSelectOptions(this.deliveryEnabled);
                            this.language.languageInitialized.next(true);
                        });
                    this.backend.GetFaultSuggestions()
                        .subscribe(suggestions => {
                            this.faultSuggestions = suggestions.suggestions.filter(x => x.type == FaultSuggestionType.Fault).map(x => x.name);
                            this.repairPartSuggestions = suggestions.suggestions.filter(x => x.type == FaultSuggestionType.RepairPart).map(x => x.name);
                        });
                }
            });
    }

    async logOut(): Promise<void> {
        this.clearAuthToken();
        this.clearRefreshToken();

        this.onLogoutEvent.next();

        await this.navCtrl.navigateRoot(['/login']);

        this.httpCache.clear();
        if (!this.platform.isMobile) {
            (<PageReuseStrategy> this.routeReuse).clear();
        }

        this.init();
    }

    // endregion

    // region Methods

    async refreshAccessToken() {
        let response = await this.backend.RefreshAccessToken({ token: this.getAuthToken(), refreshToken: this.getRefreshToken(), platform: this.platform.getDevicePlatform() })
            .toPromise();
        console.log('Token refresh successful!');
        this.extractAuthToken(response.headers);
    }

    extractAuthToken(headers: HttpHeaders): string {
        let authToken = headers.get(CustomHeaders.Authorization);
        this.extractUserDataFromAuthToken(authToken);
        this.setAuthToken(authToken);

        let refreshToken = headers.get(CustomHeaders.RefreshToken);
        this.setRefreshToken(refreshToken);

        return authToken;
    }

    extractUserDataFromAuthToken(authToken: string): void {
        if (!authToken) {
            return;
        }

        let decoded = JSON.parse(this.base64UrlDecode(authToken.split('.')[1]));

        const idProperty: string = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'; // NameIdentifier claim contains the user id
        this.userId = decoded.hasOwnProperty(idProperty) ? decoded[idProperty] : null;

        const accountNameProperty: string = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata';
        this.userEMail = decoded.hasOwnProperty(accountNameProperty) ? decoded[accountNameProperty] : null;

        const nameProperty: string = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname';
        this.userName = decoded.hasOwnProperty(nameProperty) ? decoded[nameProperty] : null;

        const loginUserTypeProperty: string = 'https://ndru.com/claims/usertype';
        this.loginUserType = decoded.hasOwnProperty(loginUserTypeProperty) ? LoginUserType[decoded[loginUserTypeProperty]] as any : null;
    }

    isAuthTokenExpired(authToken: string): boolean {
        if (!authToken) {
            return true;
        }
        let decoded = JSON.parse(this.base64UrlDecode(authToken.split('.')[1]));

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);

        return !(date.valueOf() > new Date().valueOf());
    }

    private base64UrlDecode(base64UrlString: string): string {
        // Replace URL-specific characters
        let base64 = base64UrlString.replace(/-/g, '+').replace(/_/g, '/');
    
        // Add missing padding
        while (base64.length % 4 !== 0) {
            base64 += '=';
        }
    
        // Decode using atob
        return atob(base64);
    }

    // endregion
}
